<mat-card>
  <mat-card-content>
    <span #customValDiv>
      <div *ngFor="let item of inputarray;"
        [ngClass]="{'border-2 border-solid border-[#227dbf]': item.text===selectedArry[item.text]}"
        class="text-center inline-block w-[35px] h-[35px] box-border cursor-pointer m-[5px] pt-[7px] rounded-[50%]"
        (click)="itemClick(item.value)">
        {{item.text}}
      </div>
    </span>
  </mat-card-content>
  <mat-card-actions>
    <button type="button" color="warn" mat-button (click)="onCancel()">Cancel</button>
    <button type="button" color="primary" mat-button (click)="setValues()">Set</button>
  </mat-card-actions>
</mat-card>