<div fxLayout fxLayoutAlign="center center" class="border rounded-lg border-solid border-[#ddd]">
  <button mat-icon-button class="leading-[30px]" matTooltip="Zoom Out" (click)="zoomOut()">
    <!-- <fa-icon [icon]="faMinus"></fa-icon> -->
    <mat-icon>remove</mat-icon>
  </button>
  <span class="text-base px-2.5 py-0">{{ zoom }}%</span>
  <button mat-icon-button class="leading-[30px]" matTooltip="Zoom In" (click)="zoomIn()">
    <!-- <fa-icon [icon]="faPlus"></fa-icon> -->
    <mat-icon>add</mat-icon>
  </button>
</div>