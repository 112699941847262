import { MyToastrService } from './../../../_services/toastr.service';
import { BaseRequestService } from 'app/_services/base.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { BooleanInput } from '@angular/cdk/coercion';
import { Subject, takeUntil } from 'rxjs';
import { User } from 'app/core/user/user.types';
import { UserService } from 'app/core/user/user.service';
import { AuthService } from '../../../core/auth/auth.service';
import { LoaderService } from 'app/_services/loader.service';
import { log } from 'fabric/fabric-impl';
import { NotificationService } from 'app/_services/notification.service';
import { AuthenticationService } from 'app/_services/authentication.service';

@Component({
    selector: 'user',
    templateUrl: './user.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'user'
})
export class UserComponent implements OnInit, OnDestroy {
    /* eslint-disable @typescript-eslint/naming-convention */
    static ngAcceptInputType_showAvatar: BooleanInput;
    /* eslint-enable @typescript-eslint/naming-convention */

    @Input() showAvatar: boolean = true;
    user: any = {};
    role: any = '';
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    isAuthenticated = true;
    currentUser: User | undefined;
    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router, private toast: MyToastrService,
        private _userService: UserService, private baseService: BaseRequestService, private authenticationService: AuthenticationService,
        private _authService: AuthService, private loaderService: LoaderService, public notifyService: NotificationService
    ) {
        this.user = this._authService._user;
        // console.log('user', this.user);
        this.isAuthenticated = false;
        this.currentUser = undefined;
        // this.resultOut = undefined;
        // this.currentUserChange.next(this.currentUser);
        // this.resultOutChange.next(this.resultOut);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // console.log(this._authService);
    }


    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the user status
     *
     * @param status
     */
    updateUserStatus(status: string): void {
        // Return if user is not available
        if (!this.user) {
            return;
        }

        // Update the user
        this._userService.update({
            ...this.user,
            status
        }).subscribe();
    }

    /**
     * Sign out
     */
    signOut(): void {
        this.authenticationService.logout()
        // this._router.navigate(['/sign-out']);  
        // this.baseService.doRequest('/api/logout', 'get').subscribe((re: any) => {
        //     this.isAuthenticated = false;
        //     this.currentUser = undefined;
        //     localStorage.clear();
        //     this._router.navigate(['/sign-out']);
        // }, (err: any) => {
        //     this.isAuthenticated = false;
        //     this.currentUser = undefined;
        //     localStorage.clear();
        //     window.location.href = `${err.error.url}`;
        // });
    }
    navigateTo(url: any): any {
        this._router.navigateByUrl(url).then(r => {} );
        // console.log(r));
    }
}


