/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    /*{
        id: 'dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/status'
    },*/
    {
        id: 'projects',
        title: 'My Projects',
        type: 'basic',
        icon: 'heroicons_outline:collection',
        link: '/projects'
    },
    {
        id: 'label-template',
        title: 'Template Manager',
        type: 'basic',
        icon: 'heroicons_outline:view-grid-add',
        link: '/label-template'
    },
    {
        id: 'brand-manager',
        title: 'Brand Manager',
        type: 'basic',
        icon: 'heroicons_outline:inbox',
        link: '/brand-manager'
    },   
    {
        id: 'class-manager',
        title: 'Class Manager',
        type: 'basic',
        icon: 'heroicons_outline:briefcase',
        link: '/class-manager'
    },    
    {
        id:'cultivar-manager',
        title: 'Cultivar Manager',
        type: 'basic',
        icon:'heroicons_outline:server',
        link:'/cultivar-manager'
    },
    {
        id: 'product-manager',
        title: 'Product Manager',
        type: 'basic',
        icon: 'heroicons_outline:clipboard',
        link: '/product-manager'
    },    
    {
        id: 'product-size-manager',
        title: 'Product Size Manager',
        type: 'basic',
        icon: 'heroicons_outline:archive',
        link: '/product-size-manager'
    },  
    // {
    //     id: 'product-template',
    //     title: 'Project Template',
    //     type: 'basic',
    //     icon: 'heroicons_outline:newspaper',
    //     link: '/project-template'
    // },  
    {
        id: 'users',
        title: 'Users',
        type: 'basic',
        icon: 'heroicons_outline:users',
        link: '/users'
    },
    {
        id: 'settings',
        title: 'Settings',
        type: 'basic',
        icon: 'heroicons_outline:cog',
        link: '/settings'
    },
    /*{
        id: 'label',
        title: 'Label',
        type: 'basic',
        icon: 'heroicons_outline:cog',
        link: '/label'
    },
    {
        id: 'fabric',
        title: 'Fabric',
        type: 'basic',
        icon: 'heroicons_outline:cog',
        link: '/fabric'
    }*/
];
export const compactNavigation: FuseNavigationItem[] = defaultNavigation
export const futuristicNavigation: FuseNavigationItem[] = defaultNavigation
export const horizontalNavigation: FuseNavigationItem[] = defaultNavigation
/*export const compactNavigation: FuseNavigationItem[] = [
    {
        id   : 'status',
        title: 'Status',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/status'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id   : 'status',
        title: 'Status',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/status'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id   : 'status',
        title: 'Status',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/status'
    }
];*/
